// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { useTransition, animated } from "react-spring";

// TODO: useGesture to allow swiping/drag to change slides

const Slideshow = ({ 
	options = { 
		timer: null 
	}, 
	children 
}) => {

	// Content
	const slides = React.Children.toArray(children)

	// UX
	const [currentIndex, setIndex] = useState(0)

	const transitions = useTransition(slides[currentIndex], slide => slide?.key, {
		from: { opacity: 0 },
		enter: { opacity: 1},
		leave: { opacity: 0, position: 'absolute', top: 0 }
	})

	// UI
	const prev = () => {
		setIndex(prevState => {
			return prevState > 0 ? prevState - 1 : slides.length - 1
		})
  }

  const next = () => {
		setIndex(prevState => {
			return prevState < slides.length - 1 ? prevState + 1 : 0
		})
  }

	const handleClick = (event) => {
		if (event.clientX < (window.innerWidth / 2)) {
			prev()
		} else {
			next()
		}
	}

	// Autoplay
	useEffect(() => {
		let autoplay
		if (options.timer) {
			autoplay = setInterval(next, options.timer);
		}
		return () => clearInterval(autoplay)
	}, [])

	return (
		<div className="slideshow" onClick={handleClick}>
			{transitions.map(({ item, key, props }) => {
				return (
					<animated.div className="slideshow__slide" key={key} style={props}>
						{item}
					</animated.div>
				)
			})}
		</div>
	)
}

export default Slideshow