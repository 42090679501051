// Dependencies
import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
// Hooks
// ...
// Components
import Layout from "../components/Layout"
import Gallery from "../components/Gallery"
import ProjectLink from "../components/ProjectLink"
import PageContent from "../components/PageContent"

const sortRelated = sectors => {
  const array = []
  sectors &&
    sectors.forEach(sector => {
      sector.project?.forEach(project => {
        const check = array.filter(item => item.slug === project.slug)
        if (check.length < 1) {
          array.push(project)
        }
      })
    })
  return array
}

const ProjectTemplate = ({ data, pageContext }) => {
  // Content
  const {
    title,
    gallery,
    sector,
    metaTitle,
    metaDescription,
    shareImage,
    relatedProjects,
  } = data.contentfulProject
  const relatedDefault = useRef(sortRelated(sector))
  const related = relatedProjects || relatedDefault.current

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      shareImage={shareImage ? shareImage.fluid.src : ""}
      headerTitle={pageContext.title}
    >
      <Gallery slides={gallery} />

      <PageContent {...data.contentfulProject}>
        {related.length && (
          <div className="color-invert related-works">
            <h3>Related works</h3>

            <div className="grid-4 mtx2">
              {related.map((entry, index) => (
                <ProjectLink {...entry} key={`related-${index}`} />
              ))}
            </div>
          </div>
        )}
      </PageContent>
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectTemplate

export const ProjectTemplatePageQuery = graphql`
  query ProjectTemplateQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      metaTitle
      metaDescription
      shareImage: thumbnail {
        fluid(maxWidth: 600) {
          src
        }
      }
      title
      slug
      sector {
        slug
        project {
          title
          slug
          thumbnail {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_noBase64
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 520
              quality: 80
              formats: JPG
            )
          }
        }
      }
      gallery {
        id
        image {
          fluid(maxHeight: 960, quality: 95) {
            ...GatsbyContentfulFluid_noBase64
          }
          portrait: gatsbyImageData(
            layout: CONSTRAINED
            width: 1170
            height: 1800
            quality: 80
            formats: JPG
            resizingBehavior: FILL
          )
          landscape: gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            formats: JPG
          )
        }
        description {
          raw
          references {
            ... on ContentfulCompanyValue {
              contentful_id
              __typename
              colour
            }
          }
        }
      }
      information {
        raw
      }
      details {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            id
            title
            file {
              url
            }
            fluid(maxHeight: 800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
      relatedProjects {
        title
        slug
        thumbnail {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_noBase64
          }
          gatsbyImageData(
            layout: CONSTRAINED
            width: 520
            quality: 80
            formats: JPG
          )
        }
      }
    }
  }
`
