// Dependencies
import React, { useState, useEffect, useRef } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { EffectFade } from 'swiper';
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
// Hooks
import useMouse from "../hooks/useMouse";
import useMedia from "../hooks/useMedia";
// Components
// import Img from "./Img";
import Slideshow from "../components/Slideshow";
import Image from "../components/Image";
import RichText from "./RichText";
// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/effect-fade/effect-fade.scss';

// SwiperCore.use([EffectFade]);

const Cursor = () => {

	// UX
	const [isLeft, set] = useState(false); 

	// UI
	const { x, y } = useMouse();

	useEffect(() => {
		if (x > (window.innerWidth / 2)) {
			set(false)
		} else {
			set(true)
		}
	}, [x]);

	return (
		<div className={`gallery__cursor ${isLeft ? 'left' : 'right'}`} style={{ top: y, left: x }} />
	)
}

function useCompleteSlides(slides) {
	const filter = useRef(
		slides ? slides.filter(slide => slide.image ? true : false) : []
	)
	return filter.current
}

const GallerySlide = ({ image, description, index, slidesLength }) => {
	
	// Todo
	const offsetCounter = `${index} of ${slidesLength - 1}`;

	return index > 0 ? (
		<div className="gallery__slide">
			<div className="gallery__image">
				{image && <Image image={image.landscape} />}
			</div>
			<div className="gallery__caption p3">
				<h5>{offsetCounter}</h5>
				{description && (
					<RichText content={description} />
				)}
			</div>
		</div>
	) : (
		<div className="gallery__slide">
			<div className="gallery__cover">
				{image && <Image image={image.landscape} />}
			</div>
		</div>
	)
}

const GallerySlideshow = (props) => {

	// Content
	const slides = useCompleteSlides(props.slides)

	// UX
	const [showCursor, setCursor] = useState(false)

	const handleShowCursor = () => {
		if (slides.length > 1) {
			setCursor(true)
		}
	}

	const handleHideCursor = () => {
		setCursor(false)
	}

	const handlers = {
		onMouseEnter: handleShowCursor,
		onMouseLeave: handleHideCursor,
		onMouseOver: handleShowCursor
	}

	return (
		<section className="gallery" {...handlers} style={{
			cursor: showCursor ? 'none' : 'default'
		}}>
			<Slideshow>
				{slides.map((slide, index) => {
					return (
						<GallerySlide {...slide} index={index} slidesLength={slides.length} key={`${slide.id}${index}`} />
					)
				})}
			</Slideshow>

			{showCursor && (
				<Cursor />
			)}
		</section>
	)
}

const GalleryFeed = (props) => {

	// Content
	const slides = useCompleteSlides(props.slides)

	return (
		<section className="feed">
			{slides.map((slide, index) => {

				const key = `${slide.id}-feed-slide-${index}`;

				return index > 0 ? (
					<div className="feed__slide guttersx2" key={key}>

						<Image image={slide.image.landscape} />
						<div className="p3 outer">
							{slide.description && (
								<RichText content={slide.description} />
							)}
						</div>
					</div>
				) : (
					<div className="feed__slide--cover" key={key}>
						<Image image={slide.image.portrait} />
					</div>
				)
			})}
		</section>
	)
}

const Gallery = (props) => {

	const isMobile = useMedia(
    // Media queries
    ['(min-width: 768px) and (orientation: landscape)'],
    // Column counts (relates to above media queries by array index)
    [false],
    // Default column count
    true
	);

	return isMobile ? (
		<GalleryFeed {...props} />
	) : (
		<GallerySlideshow {...props} />
	)
}

Gallery.propTypes = {
  slides: PropTypes.array.isRequired
}

export default Gallery