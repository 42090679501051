// Dependencies
import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

export const BackgroundImage = props => (
  <GatsbyImage
    {...props}
    className={`img ${props.className || ""}`}
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: "var(--z-background)",
    }}
    alt={props.alt || `Curl la Tourelle Head`}
  />
)

export const GalleryImage = props => (
  <GatsbyImage
    {...props}
    className={`img ${props.className || ""}`}
    alt={props.alt || `Curl la Tourelle Head`}
  />
)

const Image = ({ gif, ...props }) =>
  !gif ? (
    <GatsbyImage
      {...props}
      className={`img ${props.className || ""}`}
      alt={props.alt || `Curl la Tourelle Head`}
    />
  ) : (
    <img
      src={gif}
      className={`img ${props.className || ""}`}
      alt={props.alt || `Curl la Tourelle Head`}
    />
  )

export default Image
