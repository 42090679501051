// Dependencies
import React from "react";
import { Link } from "gatsby";
// Hooks
import Image from "../components/Image";

const ProjectLink = ({ title, slug, thumbnail }) => (
	<Link to={`/project/${slug}`} className="project-thumbnail">
		<div className="thumbnail">
			{thumbnail && <Image image={thumbnail.gatsbyImageData} alt={thumbnail.title || ''} />}
		</div>
		<h5 className="">{title}</h5>
	</Link>
)

export default ProjectLink