// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
// Hooks
import usePeek from "../hooks/usePeek";
// Components
import RichText from "../components/RichText";

const animateScroll = (endY, duration) => {

  var startY = window.scrollY || document.body.scrollTop,
      startT  = +(new Date()),
      finishT = startT + duration;

  var interpolate = function (source, target, shift) {
    return (source + (target - source) * shift);
  };

  var easing = function (pos) {
    return (-Math.cos(pos * Math.PI) / 2) + .5;
  };

  var animate = function() {
    var now = +(new Date()),
        shift = (now > finishT) ? 1 : (now - startT) / duration;

    window.scrollTo(0, interpolate(startY, endY, easing(shift)));

    (now > finishT) || setTimeout(animate, 15);
  };

  animate();

	// Usage example
	// animateScroll(window.innerHeight, 1000)
}

const BackButton = ({ className, children }) => {

	const handleClick = () => {
		window.history.back()
	}

	return (
		<button className={className} onClick={handleClick}>{children}</button>
	)
}

const PageContent = ({ information, details, children }) => {

	// UX
	// const style = usePeek();
	const SPEED = 500
	const PEEK_DISTANCE = 120
	const ref = useRef()
	const [willPeek, setPeek] = useState(false)
	const [hasScrolled, setScrolled] = useState(false)

	const handlePeek = useCallback(() => {
		if (!hasScrolled) {
			if (willPeek) {
				animateScroll(window.scrollY + PEEK_DISTANCE, SPEED)
			} else {
				animateScroll(0, SPEED)
			}
		}
	}, [willPeek, hasScrolled])
	useEffect(handlePeek, [willPeek])

	const handleMouseMove = (event) => {
		const near = event.clientY > window.innerHeight * 0.8;
		if (near) {
			setPeek(true)
		} else {
			setPeek(false)
		}
	}

	const handleScroll = () => {
		if (window.scrollY > PEEK_DISTANCE) {
			setScrolled(true)
		}
	}

	const cleanup = () => {
		window.removeEventListener('mousemove', handleMouseMove, false)
		window.removeEventListener('scroll', handleScroll, false)
	}

	useEffect(() => {
		// Handler...
		window.addEventListener('mousemove', handleMouseMove)
		return () => window.removeEventListener('mousemove', handleMouseMove, false)
	}, [])

	useEffect(() => {
		// Handler...
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll, false)
	}, [])

	useEffect(() => {
		if (hasScrolled) {
			cleanup()
		}
	}, [hasScrolled])

	const handleClick = () => {
		if (!hasScrolled) {
			setScrolled(true)
			const scrollPosition = ref.current.offsetTop - window.scrollY
			animateScroll(scrollPosition, SPEED)
		}
	}

  return (
    <section className="page-content will-peek" onClick={handleClick} ref={ref}>
			<div className="grid-2 guttersx2">
				{details && (
					<div className="page__details p3 outer has-links has-break-indents">
						<RichText content={details} />
					</div>
				)}
				
				{information && (
					<div className="outer has-visible-links">
						<RichText content={information} />
					</div>
				)}
			</div>
			
			<div className="guttersx2 inner">
				<BackButton className="h3">{`< Back`}</BackButton>
			</div>
			
			{children}
		</section>
  )
}

export default PageContent